import React from 'react'
import YTALink from '@/utilities/YTALink'

//https://stackoverflow.com/a/61281828
interface BlockBasics {
  title: string
  children?: React.ReactNode
  styleClass?: string
}

interface BlockWithFooter extends BlockBasics {
  footer: string
  url?: never
  linkText?: never
}

interface BlockWithLink extends BlockBasics {
  url: string
  linkText: string
  footer?: never
}

type BlockProps = BlockWithFooter | BlockWithLink

export const Block = ({
  title,
  footer,
  url,
  linkText,
  children,
  styleClass,
}: BlockProps) => {
  return (
    <div
      className={`${
        styleClass ? 'block ' + styleClass : 'block col-sm-12 col-md-6 mb-5'
      }`}
    >
      <h2 className="block--title h4 mb-2">{title}</h2>
      <div className="block--content">{children}</div>
      <div className="block--footer">
        {url && <YTALink url={url}>{linkText}</YTALink>}

        {footer && <>{footer}</>}
      </div>
    </div>
  )
}

export default Block
