import React from 'react'
import { graphql } from 'gatsby'

import Block from '@/components/Block'
import Hero from '@/components/Hero'
import Video from '@/utilities/Video'
import Layout from '@/components/Layout'
import Message from '@/components/Message'
import Navigation from '@/components/Navigation'

interface PageTemplateProps {
  data: {
    markdownRemark: {
      html: string
      frontmatter: any
    }
  }
}

const Home = ({ data }: PageTemplateProps) => {
  const pageInfo = data.markdownRemark

  return (
    <Layout
      pageTitle={'Startpagina'}
      heroTitle="Jiu-Jitsu<br/>Yukio Tani Aalst"
      heroSubTitle={pageInfo.frontmatter?.subTitle || ''}
      heroTagLine={pageInfo.frontmatter?.tagline || ''}
      heroImage={pageInfo.frontmatter?.hero || ''}
    >
      {data.markdownRemark.html && (
        <Message content={pageInfo.html} type="alert" collapse={true} />
      )}

      <div className="row justify-content-center pb-3">
        <Block
          title="Woensdag &amp; vrijdag"
          url="/trainingen"
          linkText="Meer info"
        >
          <dl className="row">
            <dt className="col-sm-8">Juniores (6–9jaar)</dt>
            <dd className="col-sm-4">18–18u50</dd>
            <dt className="col-sm-8">Juniores (10–13jaar)</dt>
            <dd className="col-sm-4">19–20u</dd>
            <dt className="col-sm-8">Seniores (vanaf 14 jaar)</dt>
            <dd className="col-sm-4">20–21u30</dd>
          </dl>
        </Block>

        <Block title="Dojo" url="dojo" linkText="Meer info">
          <>
            <address>
              Jan De Windtstraat 59
              <br />
              9300 Aalst
            </address>
          </>
        </Block>
      </div>
      <div className="row justify-content-center pb-3">
        <Video videoID="CNlzDPVBIO8" description="Jiu-Jitsu Yukio Tani Aalst" />
      </div>
      <div className="row justify-content-center pb-3">
        <Video
          videoID="GNnvVDDL3tI"
          description="Yukio Tani Aalst - training"
        />

        <Video
          videoID="abuiks52zOs"
          description="Yukio Tani Aalst - Leslie Ottoey Sportpromotie 2019"
        />
      </div>
    </Layout>
  )
}

export default Home

export const indexPageQuery = graphql`
  query indexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subTitle
        tagline
        hero {
          childImageSharp {
            fluid(maxWidth: 400, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    }
  }
`
